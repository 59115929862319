
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import GRAPH_TYPE from '@/modules/markets/constants/graph-types.constant';
import type CompsetsService from '@/modules/compsets/compsets.service';
import MarketsGraphHotels from '@/modules/markets/components/graph/markets-graph-hotels.vue';
import type MarketsService from '@/modules/markets/markets.service';
import MarketsGraphHeader from './markets-graph-header.vue';
import MarketsGraphRange from './markets-graph-range.vue';

@Component({
    components: {
        MarketsGraphRange, MarketsGraphHotels, MarketsGraphHeader,
    },
})
export default class GraphSet extends Vue {
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;
    @inject(KEY.MarketsService) private marketsService!: MarketsService;

    get providers() {
        return this.compsetsService.currentCompset?.marketProviders || [];
    }

    isRange(provider: string) : boolean {
        return this.marketsService.getGraphType(provider) === GRAPH_TYPE.RANGE;
    }
}
